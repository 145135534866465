<template>
  <div class="workspace-collaboration-content">
    <bookmark-toolbar
      :pinPost="pinPosts"
      :bookmarks="bookmarks"
      :bookmarkFolders="bookmarkFolders"
      :labels="taskLabels"
    />
    <div
      v-if="(postsLoading && posts && posts.length === 0) || messagingLoader"
      class="layout items-center justify-center"
      style="height: calc(100vh - 44px)"
    >
      <q-circular-progress
        indeterminate
        size="75px"
        :thickness="0.1"
        color="primary"
        class="q-mb-md"
      />
    </div>
    <div
      class="column bg-white relative-position"
      style="height: calc(100vh - 115px)"
      @click.stop="$emit('closeNotificaiton')"
      v-else
    >
      <div
        class="walkthrough walkthrough-large"
        v-if="user.onboarding.is_messaging"
      >
        <div class="icon">
          <q-icon
            :name="$icons.matChatBubble"
            size="24px"
            style="color: #007aff"
          />
        </div>
        <div class="content">
          <div class="video" @click="videoPlayer">
            <q-icon
              class="play"
              color="primary"
              :name="$icons.mdiPlay"
              size="46px"
            />
          </div>
        </div>

        <div class="text-contents">
          <span class="text-bold" style="margin-left: 31px"
            >Here is the messaging area of this workspace</span
          >
          <p>
            Send & receive messages, share files, create tasks out of messages,
            view read receipts, export your conversation and much more.
          </p>
          <div
            style="width: 50px; margin-left: 31px; margin-top: 10px"
            class="d-flex"
          >
            <!-- <q-btn
              dense
              no-caps
              class="primary-button"
              color="#15d89a"
              size="md"
              label="Learn more"
              @click="$router.push({ name: 'LearnView' })"
            /> -->
            <q-btn
              dense
              no-caps
              style="margin-left: 10px; height: 32px; border: solid 1px #677484"
              size="md"
              label="Dismiss"
              @click="closeWalkthroughBanner"
            />
          </div>
        </div>
        <div class="close" @click="closeWalkthroughBanner">
          <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
        </div>
      </div>
      <div class="empty-messages" v-if="posts && posts.length === 0">
        <div class="icon-container">
          <q-icon class="icon" :name="$icons.matTextsms" />
        </div>
        <div class="content">
          <p class="bold">No messages shared</p>
          <p>Type your message below to get the conversation going!</p>
        </div>
      </div>
      <div class="row justify-center">
        <div
          v-if="unreadMessageCount.length > 0"
          class="row unread-bar rounded-borders q-mx-md q-my-sm"
          style="max-width: 720px; width: 100%"
        >
          <div
            v-if="unreadMessageCount.length <= 1"
            class="flex-1 text-center text-white"
          >
            {{ unreadMessageCount.length }} message marked as unread
          </div>
          <div v-else class="flex-1 text-center text-white">
            {{ unreadMessageCount.length }} messages marked as unread
          </div>
          <div class="action-btn">
            <q-btn
              @click="readAllMessage"
              style="width: 30px"
              dense
              size="sm"
              flat
              class="q-ma-none"
            >
              <q-icon color="white" :name="$icons.fasCheck" size="12px" />
              <q-tooltip>Mark All Message Read</q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
      <threads
        ref="myThread"
        :user="user"
        :labels="taskLabels"
        :workspaceMembers="workspaceMembers"
        :drawerRight="drawerRight"
        :readMessageNotifications="readMessageNotificationsHandler"
        @clickonmoreoption="clickonmoreoption"
        @deleteCommentPoint="deleteCommentPoint"
        @updatepositions="updatepositions"
        @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
        @updateComment="updateComment"
        @sendComment="sendComment"
        @deleteMediaComment="deleteMediaComment"
        :workspaces="workspaces"
        :posts="posts"
        @closeMediaModel="closeMediaModel"
        :imageList="imageList"
        @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
        @hideDragAndDropMediaUpload="shouldShowDragAndDropMediaUpload = false"
        @showDragAndDropMediaUpload="shouldShowDragAndDropMediaUpload = true"
        :messageSearch="messageSearch"
        :scrollToBottom="scrollToBottom"
        :createTaskFromMessage="createTaskFromMessage"
        :editDoc="editDoc"
        :openFolder="openFolder"
        :folderList="folderList"
      ></threads>
      <div class="row justify-between items-center q-px-md">
        <div
          :style="{
            'max-width': '720px',
            width: '100%',
            margin: '12px auto 24px auto',
            bottom: '0px',
          }"
          class="row flex-1"
        >
          <collaboration-editor
            :mentions="workspaceMembers"
            :workspace="currentWorkspace"
            :currentUser="user"
            :taskLabels="taskLabels"
            :isWorkspaceOwner="isWorkspaceOwner"
            :readMessageNotifications="readMessageNotificationsHandler"
            @clickonmoreoption="clickonmoreoption"
            @deleteCommentPoint="deleteCommentPoint"
            @updatepositions="updatepositions"
            @closeMediaModel="closeMediaModel"
            @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
            @updateComment="updateComment"
            @sendComment="sendComment"
            @deleteMediaComment="deleteMediaComment"
            :imageList="imageList"
            :shouldShowDragAndDropMediaUpload="shouldShowDragAndDropMediaUpload"
            :scrollToBottom="scrollToBottom"
            :editDoc="editDoc"
            :openFolder="openFolder"
          />
          <typing-indicators
            :workspaceId="currentWorkspaceId"
            :companyId="currentCompany.companyId"
            :userId="user && user.id"
          />
        </div>
        <button
          :class="['fab-btn-message q-ml-md']"
          style="margin-bottom: 16px"
          v-if="isVisible([1, 2, 3])"
        >
          <q-icon :name="$icons.mdiPlusThick" size="20px" color="white" />
          <q-menu
            content-style="width:150px;max-height:200px;box-shadow:0px 2px 10px 0px rgb(0 0 0 / 19%)"
            v-model="fabMenu"
            auto-close
            :offset="[-20, 0]"
          >
            <q-list class="q-py-sm">
              <q-item
                @click="openDocEditor(currentWorkspaceId)"
                :class="['items-center q-px-lg']"
                clickable
                style="font-size: 15px"
              >
                <q-icon
                  :name="$icons.matDescription"
                  size="18px"
                  style="margin-right: 20px"
                />
                New doc
              </q-item>
              <q-item
                @click="createNewTask"
                :class="['items-center q-px-lg']"
                clickable
                style="font-size: 15px"
              >
                <q-icon
                  :name="$icons.fasCheck"
                  size="18px"
                  style="margin-right: 20px"
                />
                New task
              </q-item>
            </q-list>
          </q-menu>
        </button>
        <!-- <div class="create-new-task-doc row items-center q-mb-md">
          <div
            @click="createNewTask"
            class="btn task row items-center q-px-sm cursor-pointer q-mr-md"
          >
            <q-tooltip>
              Create new task
            </q-tooltip>
            <div class="btn-icon">
              <q-icon size="18px" :name="$icons.mdiPlusThick" />
            </div>
            <div class="btn-lbl flex-1 q-ml-sm">
              Task
            </div>
          </div>
          <div
            class="btn doc row items-center q-px-sm cursor-pointer bg-positive"
            @click="openDocEditor"
          >
            <q-tooltip>
              Create new doc
            </q-tooltip>
            <div class="btn-icon">
              <q-icon size="18px" :name="$icons.mdiPlusThick" />
            </div>
            <div class="btn-lbl flex-1 q-ml-sm">
              Doc
            </div>
          </div>
        </div> -->
        <seen-indicators
          :workspaceId="currentWorkspaceId"
          :companyId="currentCompany.companyId"
          :userId="user && user.id"
          :posts="posts"
        />
      </div>
    </div>
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <new-task-dialog
      v-if="addTaskSection"
      v-model="addTaskSection"
      :closeDialog="closeNewTaskDialog"
      :workspace="currentWorkspace"
      :taskLabels="taskLabels"
      :userId="user.id"
      :showWorkspaceSection="false"
      :sequencePosition="'bottom'"
      :taskObjFromMsg="taskObjFromMsg"
    />
    <document-editor-dialog
      v-if="documentDialog"
      v-model="documentDialog"
      :openDocEditor="openDocEditor"
      :closeDocEditor="closeDocEditor"
      :documentProp="createdDoc"
      :setActiveDocument="setActiveDocument"
      :activeDocumentId="activeDocumentId"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspaceId"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="taskLabels"
      :folderList="folderList"
      :authToken="currentCompany.accessToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";

import CollaborationEditor from "@/components/Editor/CollaborationEditor";
import TypingIndicators from "@/components/TypingIndicators";
import SeenIndicators from "@/components/SeenIndicators";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";

import Threads from "@/views/MessagingView/Threads";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import UserScope from "@/mixins/UserScope";
import NewTaskDialog from "@/views/MyWorkView/NewTaskDialog";
import BookmarkToolbar from "@/views/WorkspaceView/BookmarkToolbar";
import DocumentEditorDialog from "@/components/Dialogs/DocumentEditorDialog";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";

import {
  GetNewMediaListMutation,
  UpdateNotificationsMutation,
  UpdateReadCursorMutation,
  MessagesQuery,
  NotificationsQuery,
  UpdateUserOnboardingMutation,
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
} from "@/gql";
export default {
  name: "MessagingView",
  mixins: [MediaMethodMixin, UserScope, DocumentEditorMixin],
  props: ["messageSearch", "drawerRight", "toggleDrawerRight"],
  components: {
    CollaborationEditor,
    TypingIndicators,
    SeenIndicators,
    Threads,
    VideoDialog,
    NewTaskDialog,
    BookmarkToolbar,
    DocumentEditorDialog,
    FolderDetailsDialog,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    posts: {
      defaultValue: [],
      cacheKey() {
        return `MessagesQuery:${this.currentWorkspaceId}`;
      },
    },
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceId}`;
      },
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    workspaceMembers: {
      defaultValue: [],
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceId}`;
      },
    },
    pinPosts: {
      defaultValue: [],
      cacheKey() {
        return `PinMessagesQuery:${this.currentWorkspaceId}`;
      },
    },
    bookmarks: {
      defaultValue: [],
      cacheKey() {
        return `BookmarksQuery:${this.currentWorkspaceId}`;
      },
    },
    bookmarkFolders: {
      defaultValue: [],
      cacheKey() {
        return `BookmarkFoldersQuery:${this.currentWorkspaceId}`;
      },
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
      defaultValue: [],
    },
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  data() {
    return {
      messagingLoader: false,
      onLine: navigator.onLine,
      showBackOnline: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      shouldShowDragAndDropMediaUpload: true,
      addTaskSection: false,
      taskObjFromMsg: null,
      fabMenu: false,
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
    };
  },
  methods: {
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    createNewTask() {
      this.addTaskSection = true;
    },
    closeNewTaskDialog() {
      this.addTaskSection = false;
      this.taskObjFromMsg = null;
    },
    scrollToBottom() {
      setTimeout(() => {
        if (document.querySelector("#scroll-container")) {
          document.querySelector("#scroll-container").scrollTop =
            document.querySelector("#scroll-container").scrollHeight;
        }
      }, 10);
    },
    createTaskFromMessage({ task, postMedia, messageId, fileteredUsers }) {
      this.addTaskSection = true;
      this.taskObjFromMsg = { task, postMedia, messageId, fileteredUsers };
    },
    async updateMediaCacheWithReactivity(mediaIds) {
      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });

      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspaceId}`);

      query.data.newMedia.push(...response.data.getNewMediaList);
    },
    async readMessageNotificationsHandler() {
      const query = this.$api.getQuery("NotificationsQuery");
      let notification_ids;
      if (query.data) {
        notification_ids = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.scope.id === Number(this.currentWorkspaceId))
          .filter((n) => n.action.object_type === "App\\Post")
          .map((n) => {
            n.read = true;
            return n.id;
          });
        if (notification_ids.length) {
          const variables = {
            notification_ids: notification_ids,
            read: true,
          };
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables,
          });
        }
      }
    },
    async readAllMessage() {
      this.currentWorkspace.read_cursor = 0;
      await this.$api.mutate({
        mutation: UpdateReadCursorMutation,
        variables: {
          workspace_id: this.currentWorkspaceId,
          read_cursor: 0,
        },
      });
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    async updateMessage() {
      const variables = {
        workspace_id: this.currentWorkspaceId,
      };

      await this.$api.query({
        query: MessagesQuery,
        variables,
        cacheKey: "MessagesQuery:" + this.currentWorkspaceId,
      });

      await this.$api.query({
        query: NotificationsQuery,
        cacheKey: "NotificationsQuery",
      });
    },
    async closeWalkthroughBanner() {
      this.user.onboarding.is_messaging = false;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_messaging",
          flag_value: false,
        },
      });
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/569191210",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    unreadMessageCount() {
      return this.posts.filter((p) => {
        if (
          this.currentWorkspace.read_cursor !== 0 &&
          this.currentWorkspace.read_cursor <= p.id &&
          p.user.id !== this.user.id
        ) {
          return p;
        }
        return;
      });
    },
    imageList() {
      if (this.media) {
        return orderBy(uniqBy(this.media, "id"), ["sequence"]);
        //return uniqBy(orderBy(this.media, ["sequence"]), "id");
      }
      return null;
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(this.mediaFolders, "id");
      } else {
        return [];
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.workspace !== from.params.workspace) {
        this.messagingLoader = true;
      }
    },
    posts() {
      this.messagingLoader = false;
    },
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.updateMessage();
        }, 1000);
      }
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>
<style lang="scss" scoped>
.create-new-task-doc {
  .btn {
    height: 35px;
    width: 85px;
    color: white;
    border-radius: 20px;
    transition: 0.5s all ease-in-out;
    z-index: 100;
    &:hover {
      transform: scale(1.2);
      transition: 0.5s all ease-in-out;
    }
    &.task {
      background: #007aff;
    }
    &.doc {
      background: #15d89a;
    }
  }
  .btn-icon {
  }
  .btn-lbl {
    font-size: 14px;
    font-weight: bold;
  }
}
.fab-btn-message {
  // position: absolute;
  // bottom: 20px;
  // right: 25px;
  width: 45px;
  height: 45px;
  background: #007aff;
  border-radius: 12px;
  // box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
  border: 3px solid #dbecff;
  // &.active,
  // &:hover {
  //   background: linear-gradient(222.86deg, #419cff 0%, #66afff 127.6%);
  //   border-color: #c3dfff;
  // }
}
</style>
